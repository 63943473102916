.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#video-hero {
  width: 250px;
  height: 100%;
  margin-left: 200px;
  margin-bottom: 50px;
  border: 7px solid white;
  border-radius: 30px;
}

@keyframes float {
  0% {
      transform: translatey(0px);
  }
  50% {
      transform: translatey(-10px);
  }
  100% {
      transform: translatey(0px);
  }
}

#home-hero {
  animation: float 3s ease-in-out infinite;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#list-plans {
  list-style-type: disc;
  color: #444;
  padding-left: 20px;
}

#list-plans li::marker {
  color: #e2e2e2;
}

#list-plans-exclusivo {
  list-style-type: disc;
  color: #444;
  padding-left: 20px;
}

#list-plans-exclusivo li::marker {
  color: #e2e2e2;
}

#plans-exclusive {
  box-shadow: 0 0 20px #069c0480;
}

@media screen and (max-width: 600px) {
  #logo-mobile {
    margin-top: 10px;
    width: 100%;
    justify-content: center;
  }

  #nav-content {
    display: flex;
    background: transparent;
  }

  #nav-content ul {
    display: flex;
    background: transparent;
    justify-content: center;
  }

  #fale-mobile {
    display: none;
    text-align: center;
  }

  #fale-mobile-dois {
    display: flex;
    justify-content: center;
    width: 80%;
  }

  #home {
    padding-top: 50px;
  }

  #left-col {
    width: auto;
    text-align: left;
  }

  #left-col h1 {
    font-size: xx-large;
  }

  #left-col p {
    font-size: medium;
  }

  #have-you {
    display: none;
  }

  #home-hero {
    display: none;
  }
}
